import React from 'react';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Chip
} from '@mui/material';
import { formatCurrency } from '../utils/formatters';

const GameHistory = ({ history = [] }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, backgroundColor: 'background.paper' }}>
      <Typography variant="h6" gutterBottom>
        Recent Games
      </Typography>
      
      {history.length === 0 ? (
        <Typography color="text.secondary" align="center">
          No games played yet
        </Typography>
      ) : (
        <List>
          {history.map((game, index) => (
            <React.Fragment key={game.id || index}>
              <ListItem>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body1">
                        {game.symbols.join(' ')}
                      </Typography>
                      <Chip
                        label={game.win ? 'WIN!' : 'LOSS'}
                        color={game.win ? 'success' : 'error'}
                        size="small"
                      />
                    </Box>
                  }
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" component="span">
                        Bet: {formatCurrency(game.betAmount)}
                      </Typography>
                      {game.win && (
                        <Typography
                          variant="body2"
                          component="span"
                          color="success.main"
                          sx={{ ml: 2 }}
                        >
                          Won: {formatCurrency(game.winAmount)}
                        </Typography>
                      )}
                    </Box>
                  }
                />
              </ListItem>
              {index < history.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
};

export default GameHistory;
