/**
 * Форматирует числовое значение в денежный формат
 * @param {number} amount - Сумма для форматирования
 * @returns {string} Отформатированная строка с валютой
 */
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

/**
 * Извлекает сообщение об ошибке из различных форматов ответа API
 * @param {Error|Object} error - Объект ошибки
 * @returns {string} Сообщение об ошибке
 */
export const getErrorMessage = (error) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error.response) {
    // Ошибка от axios с ответом сервера
    const { data, status } = error.response;
    
    if (typeof data === 'string') {
      return data;
    }

    if (data.message) {
      return data.message;
    }

    if (data.error) {
      return data.error;
    }

    return `Server error (${status})`;
  }

  if (error.request) {
    // Ошибка от axios без ответа сервера
    return 'No response from server. Please check your internet connection.';
  }

  if (error.message) {
    return error.message;
  }

  return 'An unexpected error occurred';
};

/**
 * Проверяет, является ли ошибка ошибкой аутентификации (401)
 * @param {Error|Object} error - Объект ошибки
 * @returns {boolean}
 */
export const isAuthError = (error) => {
  return error.response && error.response.status === 401;
};

/**
 * Проверяет, является ли ошибка ошибкой доступа (403)
 * @param {Error|Object} error - Объект ошибки
 * @returns {boolean}
 */
export const isAccessDeniedError = (error) => {
  return error.response && error.response.status === 403;
};

/**
 * Проверяет, является ли ошибка ошибкой валидации (400)
 * @param {Error|Object} error - Объект ошибки
 * @returns {boolean}
 */
export const isValidationError = (error) => {
  return error.response && error.response.status === 400;
};

/**
 * Извлекает ошибки валидации из ответа сервера
 * @param {Error|Object} error - Объект ошибки
 * @returns {Object|null} Объект с ошибками валидации или null
 */
export const getValidationErrors = (error) => {
  if (!isValidationError(error)) {
    return null;
  }

  const { data } = error.response;

  if (data.errors && typeof data.errors === 'object') {
    return data.errors;
  }

  if (data.validationErrors && typeof data.validationErrors === 'object') {
    return data.validationErrors;
  }

  return null;
};

/**
 * Проверяет, является ли сумма ставки валидной
 * @param {string} amount - Сумма ставки
 * @param {number} minBet - Минимальная сумма ставки
 * @param {number} maxBet - Максимальная сумма ставки
 * @param {number} balance - Баланс пользователя
 * @returns {string} Сообщение об ошибке или пустая строка, если сумма ставки валидна
 */
export const validateBetAmount = (amount, minBet, maxBet, balance) => {
  if (!amount || isNaN(amount)) {
    return 'Please enter a valid bet amount';
  }

  const betAmount = Number(amount);

  if (betAmount < minBet) {
    return `Minimum bet amount is ${formatCurrency(minBet)}`;
  }

  if (betAmount > maxBet) {
    return `Maximum bet amount is ${formatCurrency(maxBet)}`;
  }

  if (betAmount > balance) {
    return `Insufficient balance. Your balance is ${formatCurrency(balance)}`;
  }

  return '';
};

/**
 * Форматирует выигрыш для отображения
 * @param {Object} result - Результат игры
 * @returns {string} Отформатированное сообщение о выигрыше
 */
export const formatWinMessage = (result) => {
  if (!result) return '';

  if (result.win) {
    return `Congratulations! You won ${formatCurrency(result.winAmount)}!`;
  }

  return 'Better luck next time!';
};
