import React from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatCurrency } from '../utils/formatters';

const BettingPanel = ({
  balance,
  betAmount,
  onBetChange,
  onSpin,
  minBet,
  maxBet,
  disabled
}) => {
  const handleIncreaseBet = () => {
    const newBet = Math.min(Number(betAmount) + 1, maxBet);
    onBetChange(newBet.toString());
  };

  const handleDecreaseBet = () => {
    const newBet = Math.max(Number(betAmount) - 1, minBet);
    onBetChange(newBet.toString());
  };

  const handleQuickBet = (multiplier) => {
    const newBet = Math.min(Math.max(minBet * multiplier, minBet), maxBet);
    onBetChange(newBet.toString());
  };

  return (
    <Paper elevation={3} sx={{ p: 3, backgroundColor: 'background.paper' }}>
      <Grid container spacing={2}>
        {/* Balance Display */}
        <Grid item xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            Your Balance: {formatCurrency(balance)}
          </Typography>
        </Grid>

        {/* Bet Controls */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton 
              onClick={handleDecreaseBet}
              disabled={disabled || Number(betAmount) <= minBet}
            >
              <RemoveIcon />
            </IconButton>
            
            <TextField
              fullWidth
              label="Bet Amount"
              type="number"
              value={betAmount}
              onChange={(e) => onBetChange(e.target.value)}
              disabled={disabled}
              inputProps={{
                min: minBet,
                max: maxBet,
                step: 1
              }}
              helperText={`Min: ${formatCurrency(minBet)} | Max: ${formatCurrency(maxBet)}`}
            />

            <IconButton 
              onClick={handleIncreaseBet}
              disabled={disabled || Number(betAmount) >= maxBet}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>

        {/* Quick Bet Buttons */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
            <Tooltip title="Minimum Bet">
              <Button
                variant="outlined"
                size="small"
                onClick={() => onBetChange(minBet.toString())}
                disabled={disabled}
              >
                Min
              </Button>
            </Tooltip>
            <Tooltip title="Double Minimum">
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleQuickBet(2)}
                disabled={disabled}
              >
                2x Min
              </Button>
            </Tooltip>
            <Tooltip title="Maximum Bet">
              <Button
                variant="outlined"
                size="small"
                onClick={() => onBetChange(maxBet.toString())}
                disabled={disabled}
              >
                Max
              </Button>
            </Tooltip>
          </Box>
        </Grid>

        {/* Spin Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={onSpin}
            disabled={disabled || Number(betAmount) > balance}
          >
            Spin!
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BettingPanel;
