import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    IconButton,
    Paper,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Collapse,
    Card,
    CardHeader,
    CardContent
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SymbolConstructor = ({ symbols = [], onChange }) => {
    const [editingId, setEditingId] = useState(null);
    const [isExpanded, setIsExpanded] = useState(true);
    const [newSymbol, setNewSymbol] = useState({
        name: '',
        image: '',
        type: 'regular',
        multiplier: 1
    });

    const handleAddSymbol = () => {
        if (newSymbol.name && newSymbol.image) {
            const symbolToAdd = {
                ...newSymbol,
                id: Date.now()
            };
            onChange([...symbols, symbolToAdd]);
            setNewSymbol({
                name: '',
                image: '',
                type: 'regular',
                multiplier: 1
            });
        }
    };

    const handleEditSymbol = (symbolId) => {
        setEditingId(symbolId);
    };

    const handleUpdateSymbol = (symbolId, updatedData) => {
        onChange(symbols.map(s => 
            s.id === symbolId ? { ...s, ...updatedData } : s
        ));
        setEditingId(null);
    };

    const handleDeleteSymbol = (symbolId) => {
        onChange(symbols.filter(s => s.id !== symbolId));
    };

    const handleCancelEdit = () => {
        setEditingId(null);
    };

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Card>
            <CardHeader
                title={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="h6">Символы ({symbols.length})</Typography>
                        <IconButton onClick={toggleExpanded} size="small">
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                }
            />
            <Collapse in={isExpanded}>
                <CardContent>
                    <Paper sx={{ p: 2, mb: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Добавить новый символ
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="Название"
                                    value={newSymbol.name}
                                    onChange={(e) => setNewSymbol({ ...newSymbol, name: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="URL изображения"
                                    value={newSymbol.image}
                                    onChange={(e) => setNewSymbol({ ...newSymbol, image: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Тип</InputLabel>
                                    <Select
                                        value={newSymbol.type}
                                        label="Тип"
                                        onChange={(e) => setNewSymbol({ ...newSymbol, type: e.target.value })}
                                    >
                                        <MenuItem value="regular">Обычный</MenuItem>
                                        <MenuItem value="wild">Wild</MenuItem>
                                        <MenuItem value="scatter">Scatter</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Множитель"
                                    value={newSymbol.multiplier}
                                    onChange={(e) => setNewSymbol({ ...newSymbol, multiplier: Number(e.target.value) })}
                                    inputProps={{ min: 0, step: 0.1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddSymbol}
                                    disabled={!newSymbol.name || !newSymbol.image}
                                >
                                    Добавить
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Изображение</TableCell>
                                    <TableCell>Название</TableCell>
                                    <TableCell>Тип</TableCell>
                                    <TableCell>Множитель</TableCell>
                                    <TableCell align="right">Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {symbols.map((symbol) => (
                                    <TableRow key={symbol.id}>
                                        <TableCell>
                                            {editingId === symbol.id ? (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    value={symbol.image}
                                                    onChange={(e) => handleUpdateSymbol(symbol.id, { image: e.target.value })}
                                                />
                                            ) : (
                                                <Box
                                                    component="img"
                                                    src={symbol.image}
                                                    alt={symbol.name}
                                                    sx={{ width: 40, height: 40, objectFit: 'contain' }}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editingId === symbol.id ? (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    value={symbol.name}
                                                    onChange={(e) => handleUpdateSymbol(symbol.id, { name: e.target.value })}
                                                />
                                            ) : (
                                                symbol.name
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editingId === symbol.id ? (
                                                <FormControl fullWidth size="small">
                                                    <Select
                                                        value={symbol.type}
                                                        onChange={(e) => handleUpdateSymbol(symbol.id, { type: e.target.value })}
                                                    >
                                                        <MenuItem value="regular">Обычный</MenuItem>
                                                        <MenuItem value="wild">Wild</MenuItem>
                                                        <MenuItem value="scatter">Scatter</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                symbol.type === 'regular' ? 'Обычный' : 
                                                symbol.type === 'wild' ? 'Wild' : 'Scatter'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {editingId === symbol.id ? (
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    value={symbol.multiplier}
                                                    onChange={(e) => handleUpdateSymbol(symbol.id, { multiplier: Number(e.target.value) })}
                                                    inputProps={{ min: 0, step: 0.1 }}
                                                />
                                            ) : (
                                                symbol.multiplier
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {editingId === symbol.id ? (
                                                <>
                                                    <IconButton 
                                                        color="primary"
                                                        onClick={() => handleUpdateSymbol(symbol.id, symbol)}
                                                    >
                                                        <SaveIcon />
                                                    </IconButton>
                                                    <IconButton 
                                                        color="error"
                                                        onClick={handleCancelEdit}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <>
                                                    <IconButton
                                                        onClick={() => handleEditSymbol(symbol.id)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handleDeleteSymbol(symbol.id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default SymbolConstructor;
