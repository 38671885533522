import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Link, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar,
  Avatar, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  Chip, 
  CircularProgress 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { formatCurrency } from '../../utils/formatters';

const Dashboard = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalBalance: 0,
    totalGames: 0
  });
  const [activityData, setActivityData] = useState(null);
  const [topPlayers, setTopPlayers] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard`);
        const data = response.data;
        
        setStats({
          totalUsers: data.users.total,
          totalBalance: data.transactions.deposits - data.transactions.withdrawals,
          totalGames: data.slots
        });

        // Преобразуем данные для графика активности
        setActivityData([
          {
            date: new Date().toLocaleDateString(),
            games: data.slots,
            users: data.users.active
          }
        ]);

        // Формируем данные для топ игроков (пока заглушка)
        setTopPlayers([]);

        // Используем данные о транзакциях
        setRecentTransactions([]);

        setLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Панель управления
      </Typography>
      
      <Grid container spacing={3}>
        {/* Статистика пользователей */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Пользователи
            </Typography>
            <Typography component="p" variant="h4">
              {stats.totalUsers}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Всего зарегистрировано
            </Typography>
            <div>
              <Link component="button" variant="body2" onClick={() => navigate('/admin/users')}>
                Подробнее
              </Link>
            </div>
          </Paper>
        </Grid>

        {/* Финансовая статистика */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Финансы
            </Typography>
            <Typography component="p" variant="h4">
              {formatCurrency(stats.totalBalance)}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Общий баланс пользователей
            </Typography>
            <div>
              <Link component="button" variant="body2" onClick={() => navigate('/admin/transactions')}>
                Подробнее
              </Link>
            </div>
          </Paper>
        </Grid>

        {/* Игровая статистика */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Игры
            </Typography>
            <Typography component="p" variant="h4">
              {stats.totalGames}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Всего игр сыграно
            </Typography>
            <div>
              <Link component="button" variant="body2" onClick={() => navigate('/admin/games')}>
                Подробнее
              </Link>
            </div>
          </Paper>
        </Grid>

        {/* График активности */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400 }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Активность за последние 7 дней
            </Typography>
            {activityData ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Игры: {activityData[0].games}
                </Typography>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Новые пользователи: {activityData[0].users}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Топ игроков */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400 }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Топ игроков
            </Typography>
            {topPlayers ? (
              <List dense>
                {topPlayers.map((player, index) => (
                  <ListItem key={player._id}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: index < 3 ? 'secondary.main' : 'grey.400' }}>
                        {index + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={player.username}
                      secondary={`Выигрыш: ${formatCurrency(player.totalWon)}`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Последние транзакции */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Последние транзакции
            </Typography>
            {recentTransactions ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Пользователь</TableCell>
                    <TableCell>Тип</TableCell>
                    <TableCell>Сумма</TableCell>
                    <TableCell align="right">Статус</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentTransactions.map((tx) => (
                    <TableRow key={tx._id}>
                      <TableCell>{new Date(tx.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{tx.username}</TableCell>
                      <TableCell>{tx.type === 'deposit' ? 'Пополнение' : 'Вывод'}</TableCell>
                      <TableCell>{formatCurrency(tx.amount)}</TableCell>
                      <TableCell align="right">
                        <Chip 
                          label={tx.status === 'completed' ? 'Завершено' : 'В обработке'} 
                          color={tx.status === 'completed' ? 'success' : 'warning'}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
