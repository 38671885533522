import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  CircularProgress
} from '@mui/material';
import { formatCurrency } from '../utils/formatters';

const SYMBOLS = ['🍎', '🍊', '🍇', '🍒', '🍋'];
const SPIN_DURATION = 2000;

const SlotMachine = ({ onSpin, spinning, result }) => {
  const [reels, setReels] = useState(SYMBOLS.slice(0, 3));
  const [animating, setAnimating] = useState(false);

  const startAnimation = useCallback(() => {
    if (!animating) {
      setAnimating(true);
      const animationInterval = setInterval(() => {
        setReels(prevReels => prevReels.map(() => SYMBOLS[Math.floor(Math.random() * SYMBOLS.length)]));
      }, 100);

      setTimeout(() => {
        clearInterval(animationInterval);
        setAnimating(false);
      }, SPIN_DURATION);
    }
  }, [animating]);

  useEffect(() => {
    if (spinning && !animating) {
      startAnimation();
    }
  }, [spinning, animating, startAnimation]);

  useEffect(() => {
    if (result) {
      setReels(result);
    }
  }, [result]);

  return (
    <Paper elevation={3} sx={{ p: 3, backgroundColor: 'background.paper' }}>
      <Grid container spacing={2} direction="column" alignItems="center">
        {/* Slot Display */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              p: 3,
              backgroundColor: '#000',
              borderRadius: 2,
              border: '4px solid #FFD700'
            }}
          >
            {reels.map((symbol, index) => (
              <Box
                key={index}
                sx={{
                  width: 80,
                  height: 80,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#1a1a1a',
                  borderRadius: 1,
                  fontSize: '2.5rem'
                }}
              >
                {spinning && animating ? (
                  <CircularProgress size={40} />
                ) : (
                  symbol
                )}
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Result Display */}
        {result && !spinning && !animating && (
          <Grid item>
            <Typography
              variant="h6"
              color={result.win ? 'success.main' : 'text.primary'}
              align="center"
            >
              {result.win ? (
                `🎉 You won ${formatCurrency(result.winAmount)}! 🎉`
              ) : (
                'Try again!'
              )}
            </Typography>
          </Grid>
        )}

        {/* Symbols Guide */}
        <Grid item>
          <Typography variant="body2" color="text.secondary" align="center">
            Symbols: {SYMBOLS.join(' ')}
          </Typography>
        </Grid>

        {/* Spin Button */}
        <Grid item>
          <Button 
            variant="contained" 
            onClick={() => {
              startAnimation();
              onSpin();
            }} 
            disabled={spinning || animating}
          >
            {spinning || animating ? 'Крутим...' : 'Нажмите, чтобы крутить'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SlotMachine;
