import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Button,
    Grid, 
    Paper,
    Typography,
    IconButton,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Stack,
    Collapse,
    Card,
    CardHeader,
    CardContent
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';

// Готовые наборы линий для разных конфигураций
const paylinePresets = {
    3: {
        "5 линий": [
            [1,1,1], // Средняя
            [0,0,0], // Верхняя
            [2,2,2], // Нижняя
            [0,1,2], // Диагональ вниз
            [2,1,0]  // Диагональ вверх
        ],
        "9 линий": [
            [1,1,1], // Горизонтальные
            [0,0,0],
            [2,2,2],
            [0,1,2], // Диагонали
            [2,1,0],
            [0,1,1], // V-образные
            [2,1,1],
            [1,1,0],
            [1,1,2]
        ]
    },
    4: {
        "5 линий": [
            [1,1,1,1], // Средняя
            [0,0,0,0], // Верхняя
            [2,2,2,2], // Нижняя
            [0,1,1,2], // Диагональ вниз
            [2,1,1,0]  // Диагональ вверх
        ],
        "10 линий": [
            [1,1,1,1], // Горизонтальные
            [0,0,0,0],
            [2,2,2,2],
            [0,1,1,2], // Диагонали
            [2,1,1,0],
            [0,0,1,1], // Ступеньки
            [1,1,2,2],
            [2,2,1,1],
            [1,1,0,0],
            [1,0,1,0]  // Зигзаг
        ]
    },
    5: {
        "5 линий": [
            [1,1,1,1,1], // Средняя
            [0,0,0,0,0], // Верхняя
            [2,2,2,2,2], // Нижняя
            [0,1,1,1,2], // Диагональ вниз
            [2,1,1,1,0]  // Диагональ вверх
        ],
        "10 линий": [
            [1,1,1,1,1], // Горизонтальные
            [0,0,0,0,0],
            [2,2,2,2,2],
            [0,1,1,1,2], // Диагонали
            [2,1,1,1,0],
            [0,0,1,2,2], // Ступеньки
            [2,2,1,0,0],
            [1,0,0,1,1], // V-образные
            [1,2,2,1,1],
            [0,1,2,1,0]  // W-образная
        ],
        "20 линий": [
            [1,1,1,1,1], // Горизонтальные
            [0,0,0,0,0],
            [2,2,2,2,2],
            [0,1,1,1,2], // Диагонали
            [2,1,1,1,0],
            [0,0,1,2,2], // Ступеньки
            [2,2,1,0,0],
            [1,0,0,1,1],
            [1,2,2,1,1],
            [0,1,2,1,0], // W-образные
            [2,1,0,1,2],
            [0,1,1,1,0], // V-образные
            [2,1,1,1,2],
            [1,0,1,0,1], // Зигзаги
            [1,2,1,2,1],
            [0,1,0,1,0],
            [2,1,2,1,2],
            [1,1,0,1,1], // Сложные паттерны
            [1,1,2,1,1],
            [0,2,1,2,0]
        ]
    },
    6: {
        "5 линий": [
            [1,1,1,1,1,1], // Средняя
            [0,0,0,0,0,0], // Верхняя
            [2,2,2,2,2,2], // Нижняя
            [0,1,1,1,1,2], // Диагональ вниз
            [2,1,1,1,1,0]  // Диагональ вверх
        ],
        "10 линий": [
            [1,1,1,1,1,1], // Горизонтальные
            [0,0,0,0,0,0],
            [2,2,2,2,2,2],
            [0,1,1,1,1,2], // Диагонали
            [2,1,1,1,1,0],
            [0,0,1,1,2,2], // Ступеньки
            [2,2,1,1,0,0],
            [1,0,0,1,1,1], // V-образные
            [1,2,2,1,1,1],
            [0,1,2,2,1,0]  // W-образная
        ],
        "25 линий": [
            [1,1,1,1,1,1], // Горизонтальные
            [0,0,0,0,0,0],
            [2,2,2,2,2,2],
            [0,1,1,1,1,2], // Диагонали
            [2,1,1,1,1,0],
            [0,0,1,1,2,2], // Ступеньки
            [2,2,1,1,0,0],
            [1,0,0,1,1,1],
            [1,2,2,1,1,1],
            [0,1,2,2,1,0], // W-образные
            [2,1,0,0,1,2],
            [0,1,1,1,1,0], // V-образные
            [2,1,1,1,1,2],
            [1,0,1,1,0,1], // Зигзаги
            [1,2,1,1,2,1],
            [0,1,0,0,1,0],
            [2,1,2,2,1,2],
            [1,1,0,0,1,1], // Сложные паттерны
            [1,1,2,2,1,1],
            [0,2,1,1,2,0],
            [0,0,1,1,0,0], // Дополнительные паттерны
            [2,2,1,1,2,2],
            [1,0,2,2,0,1],
            [1,2,0,0,2,1],
            [0,1,2,2,1,0]
        ]
    }
};

const PaylineConstructor = ({ paylines, onChange }) => {
    const [reelCount, setReelCount] = useState(5);
    const [rowCount, setRowCount] = useState(3);
    const [currentLine, setCurrentLine] = useState(Array(reelCount).fill(false).map(() => Array(rowCount).fill(false)));
    const [selectedCells, setSelectedCells] = useState([]);
    const [isExpanded, setIsExpanded] = useState(true);
    const [selectedPreset, setSelectedPreset] = useState("");
    const [editingLineIndex, setEditingLineIndex] = useState(null);

    // Обновляем сетку при изменении количества барабанов или рядов
    useEffect(() => {
        setCurrentLine(Array(reelCount).fill(false).map(() => Array(rowCount).fill(false)));
        setSelectedCells([]);
        
        // Обновляем существующие линии под новую конфигурацию
        const updatedPaylines = paylines.map(line => {
            let newLine = line;
            
            // Обрабатываем изменение количества барабанов
            if (line.length > reelCount) {
                newLine = line.slice(0, reelCount);
            } else if (line.length < reelCount) {
                newLine = [...line, ...Array(reelCount - line.length).fill(Math.floor(rowCount / 2))];
            }

            // Обрабатываем изменение количества рядов
            newLine = newLine.map(pos => {
                if (pos >= rowCount) {
                    return Math.floor(rowCount / 2);
                }
                return pos;
            });

            return newLine;
        });
        
        onChange(updatedPaylines);
    }, [reelCount, rowCount]);

    const handleCellClick = (reelIndex, rowIndex) => {
        const newLine = currentLine.map(reel => [...reel]);
        
        if (newLine[reelIndex][rowIndex]) {
            newLine[reelIndex][rowIndex] = false;
            setCurrentLine(newLine);
            setSelectedCells(selectedCells.filter(cell => 
                !(cell.reel === reelIndex && cell.row === rowIndex)
            ));
            return;
        }

        const hasSelectedInReel = newLine[reelIndex].some(cell => cell === true);
        if (hasSelectedInReel) {
            const reelCellIndex = selectedCells.findIndex(cell => cell.reel === reelIndex);
            if (reelCellIndex !== -1) {
                newLine[reelIndex][selectedCells[reelCellIndex].row] = false;
                selectedCells.splice(reelCellIndex, 1);
            }
        }

        newLine[reelIndex][rowIndex] = true;
        setCurrentLine(newLine);
        setSelectedCells([...selectedCells, { reel: reelIndex, row: rowIndex }]);
    };

    const addPayline = () => {
        const allReelsSelected = currentLine.every(reel => reel.some(cell => cell === true));
        if (!allReelsSelected) return;

        const newPayline = Array(reelCount).fill(null);
        selectedCells.forEach(cell => {
            newPayline[cell.reel] = cell.row;
        });

        onChange([...paylines, newPayline]);
        clearCurrentLine();
    };

    const clearCurrentLine = () => {
        setCurrentLine(Array(reelCount).fill(false).map(() => Array(rowCount).fill(false)));
        setSelectedCells([]);
    };

    const removePayline = (index) => {
        const newPaylines = paylines.filter((_, i) => i !== index);
        onChange(newPaylines);
    };

    const ReelCell = ({ selected, onClick }) => (
        <Box
            onClick={onClick}
            sx={{
                width: 30,
                height: 30,
                backgroundColor: selected ? 'yellow' : 'transparent',
                border: '1px solid #666',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: selected ? 'yellow' : '#444'
                }
            }}
        />
    );

    const PaylinePreview = ({ payline }) => (
        <Grid container spacing={0.5} alignItems="center">
            {payline.map((position, index) => (
                <Grid item key={index}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                        {Array(rowCount).fill(0).map((_, i) => (
                            <Box 
                                key={i}
                                sx={{
                                    width: 20,
                                    height: 20,
                                    backgroundColor: position === i ? '#ffd700' : '#424242',
                                    border: '1px solid #666'
                                }}
                            />
                        ))}
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    // Функция для применения пресета
    const applyPreset = (presetName) => {
        if (!presetName) return;
        
        const preset = paylinePresets[reelCount][presetName];
        if (!preset) return;

        // Проверяем, что все линии в пресете валидны для текущего количества рядов
        const validLines = preset.filter(line => 
            line.every(pos => pos < rowCount)
        );

        // Заменяем все существующие линии на линии из пресета
        onChange(validLines);
        setSelectedPreset("");
    };

    // Функция для начала редактирования линии
    const startEditing = (index) => {
        setEditingLineIndex(index);
        const lineToEdit = paylines[index];
        
        // Преобразуем линию в формат сетки выбора
        const newCurrentLine = Array(reelCount).fill(false).map(() => Array(rowCount).fill(false));
        const newSelectedCells = [];
        
        lineToEdit.forEach((position, reelIndex) => {
            newCurrentLine[reelIndex][position] = true;
            newSelectedCells.push({ reel: reelIndex, row: position });
        });

        setCurrentLine(newCurrentLine);
        setSelectedCells(newSelectedCells);
    };

    // Функция для сохранения отредактированной линии
    const saveEditedLine = () => {
        if (editingLineIndex === null) return;

        const newPayline = Array(reelCount).fill(null);
        selectedCells.forEach(cell => {
            newPayline[cell.reel] = cell.row;
        });

        const newPaylines = [...paylines];
        newPaylines[editingLineIndex] = newPayline;
        onChange(newPaylines);

        // Очищаем состояние редактирования
        setEditingLineIndex(null);
        clearCurrentLine();
    };

    // Функция для отмены редактирования
    const cancelEditing = () => {
        setEditingLineIndex(null);
        clearCurrentLine();
    };

    return (
        <Card sx={{ mb: 2 }}>
            <CardHeader
                title={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6">
                            Конструктор линий выплат ({paylines.length} линий)
                        </Typography>
                        <IconButton
                            onClick={() => setIsExpanded(!isExpanded)}
                            sx={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)' }}
                        >
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                }
            />
            <Collapse in={isExpanded}>
                <CardContent>
                    {/* Настройки сетки */}
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Количество барабанов</InputLabel>
                            <Select
                                value={reelCount}
                                label="Количество барабанов"
                                onChange={(e) => setReelCount(e.target.value)}
                            >
                                <MenuItem value={3}>3 барабана</MenuItem>
                                <MenuItem value={4}>4 барабана</MenuItem>
                                <MenuItem value={5}>5 барабанов</MenuItem>
                                <MenuItem value={6}>6 барабанов</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Количество рядов</InputLabel>
                            <Select
                                value={rowCount}
                                label="Количество рядов"
                                onChange={(e) => setRowCount(e.target.value)}
                            >
                                <MenuItem value={3}>3 ряда</MenuItem>
                                <MenuItem value={4}>4 ряда</MenuItem>
                                <MenuItem value={5}>5 рядов</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>

                    {/* Выбор пресета */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>Готовые наборы линий</InputLabel>
                        <Select
                            value={selectedPreset}
                            label="Готовые наборы линий"
                            onChange={(e) => {
                                setSelectedPreset(e.target.value);
                                applyPreset(e.target.value);
                            }}
                        >
                            <MenuItem value="">Выберите набор</MenuItem>
                            {Object.keys(paylinePresets[reelCount] || {}).map(preset => (
                                <MenuItem key={preset} value={preset}>
                                    {preset}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Сетка барабанов */}
                    <Paper sx={{ p: 2, mb: 2, backgroundColor: '#333' }}>
                        <Grid container spacing={0.5} justifyContent="center">
                            {currentLine.map((reel, reelIndex) => (
                                <Grid item key={reelIndex}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                        {reel.map((selected, rowIndex) => (
                                            <ReelCell
                                                key={rowIndex}
                                                selected={selected}
                                                onClick={() => handleCellClick(reelIndex, rowIndex)}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                        <Box sx={{ mt: 2, display: 'flex', gap: 1, justifyContent: 'center' }}>
                            {editingLineIndex !== null ? (
                                <>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        onClick={saveEditedLine}
                                        disabled={!currentLine.every(reel => reel.some(cell => cell === true))}
                                    >
                                        Сохранить изменения
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={cancelEditing}
                                    >
                                        Отменить
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Tooltip title={
                                        !currentLine.every(reel => reel.some(cell => cell === true)) 
                                            ? "Выберите позицию на каждом барабане" 
                                            : ""
                                    } arrow>
                                        <span>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                startIcon={<AddIcon />}
                                                onClick={addPayline}
                                                disabled={!currentLine.every(reel => reel.some(cell => cell === true))}
                                            >
                                                Добавить линию
                                            </Button>
                                        </span>
                                    </Tooltip>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={clearCurrentLine}
                                    >
                                        Очистить
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Paper>

                    {/* Список существующих линий */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {paylines.map((payline, index) => (
                            <Paper 
                                key={index}
                                sx={{ 
                                    p: 1, 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'space-between',
                                    backgroundColor: editingLineIndex === index ? '#2d4052' : '#333'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography variant="body2" sx={{ minWidth: 80 }}>
                                        Линия {index + 1}
                                    </Typography>
                                    <PaylinePreview payline={payline} />
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton 
                                        size="small"
                                        onClick={() => startEditing(index)}
                                        disabled={editingLineIndex !== null}
                                        sx={{ color: '#4caf50' }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => removePayline(index)}
                                        disabled={editingLineIndex !== null}
                                        sx={{ color: '#ff4444' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default PaylineConstructor;
