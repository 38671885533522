import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Chip,
    Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import PaylineConstructor from '../../components/admin/PaylineConstructor';

const Slots = () => {
    const navigate = useNavigate();
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        fetchSlots();
    }, []);

    const fetchSlots = async () => {
        try {
            const response = await axios.get('/api/admin/slots');
            setSlots(response.data);
        } catch (error) {
            console.error('Error fetching slots:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Вы уверены, что хотите удалить этот слот?')) {
            try {
                await axios.delete(`/api/admin/slots/${id}`);
                setSlots(slots.filter(slot => slot._id !== id));
            } catch (error) {
                console.error('Error deleting slot:', error);
            }
        }
    };

    const handlePaylineChange = async (slotId, newPaylines) => {
        try {
            await axios.patch(`/api/admin/slots/${slotId}/paylines`, { paylines: newPaylines });
            setSlots(slots.map(slot => 
                slot._id === slotId 
                    ? { ...slot, paylines: newPaylines }
                    : slot
            ));
        } catch (error) {
            console.error('Error updating paylines:', error);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                Loading...
            </Box>
        );
    }

    return (
        <Container>
            <Box sx={{ mb: 4, mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h4" component="h1">
                        Управление слотами
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/admin/slots/create')}
                    >
                        Создать слот
                    </Button>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell>Название</TableCell>
                                <TableCell>Провайдер</TableCell>
                                <TableCell>Мин. ставка</TableCell>
                                <TableCell>Макс. ставка</TableCell>
                                <TableCell>RTP</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell align="right">Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slots.map((slot) => (
                                <React.Fragment key={slot._id}>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <IconButton
                                                size="small"
                                                onClick={() => setExpandedRow(expandedRow === slot._id ? null : slot._id)}
                                            >
                                                {expandedRow === slot._id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>{slot.name}</TableCell>
                                        <TableCell>{slot.provider}</TableCell>
                                        <TableCell>{slot.minBet}</TableCell>
                                        <TableCell>{slot.maxBet}</TableCell>
                                        <TableCell>{slot.rtp}%</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={slot.enabled ? "Активен" : "Неактивен"}
                                                color={slot.enabled ? "success" : "default"}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                size="small"
                                                onClick={() => navigate(`/admin/slots/edit/${slot._id}`)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDelete(slot._id)}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                            <Collapse in={expandedRow === slot._id} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Линии выплат
                                                    </Typography>
                                                    <PaylineConstructor
                                                        paylines={slot.paylines || []}
                                                        onChange={(newPaylines) => handlePaylineChange(slot._id, newPaylines)}
                                                    />
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
};

export default Slots;
