import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Paper,
    FormControlLabel,
    Switch,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaylineConstructor from '../../components/admin/PaylineConstructor';
import SymbolConstructor from '../../components/admin/SymbolConstructor';
import SlotInterfaceConstructor from '../../components/admin/SlotInterfaceConstructor';
import axios from 'axios';

const CreateSlot = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        provider: '',
        description: '',
        imageUrl: '',
        minBet: 1,
        maxBet: 100,
        rtp: 96,
        volatility: 'medium',
        enabled: true,
        interface: {
            gameArea: {
                x: 400,
                y: 200,
                width: 800,
                height: 600,
                background: '',
            },
            reelArea: {
                x: 250,
                y: 100,
                width: 500,
                height: 400,
                background: '',
            },
            textures: [],
            animations: [],
        }
    });

    const [paylines, setPaylines] = useState([
        [1,1,1,1,1], // Линия 1 - центральная горизонтальная
        [0,0,0,0,0], // Линия 2 - верхняя горизонтальная
        [2,2,2,2,2]  // Линия 3 - нижняя горизонтальная
    ]);

    const [symbols, setSymbols] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await axios.post('/api/admin/slots', {
                ...formData,
                paylines,
                symbols
            });
            navigate('/admin/slots');
        } catch (error) {
            console.error('Error creating slot:', error);
            // Здесь можно добавить обработку ошибок
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ mt: 4, mb: 4 }}>
                <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate('/admin/slots')}
                    sx={{ mb: 2 }}
                >
                    Назад к списку слотов
                </Button>

                <Typography variant="h4" component="h1" gutterBottom>
                    Создание нового слота
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h5" gutterBottom>
                            Основная информация
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Название"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Провайдер"
                                    name="provider"
                                    value={formData.provider}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Описание"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="URL изображения"
                                    name="imageUrl"
                                    value={formData.imageUrl}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h5" gutterBottom>
                            Настройки ставок
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Минимальная ставка"
                                    name="minBet"
                                    value={formData.minBet}
                                    onChange={handleInputChange}
                                    inputProps={{ min: 1, step: 1 }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Максимальная ставка"
                                    name="maxBet"
                                    value={formData.maxBet}
                                    onChange={handleInputChange}
                                    inputProps={{ min: formData.minBet, step: 1 }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="RTP (%)"
                                    name="rtp"
                                    value={formData.rtp}
                                    onChange={handleInputChange}
                                    inputProps={{ min: 80, max: 99, step: 0.1 }}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h5" gutterBottom>
                            Интерфейс
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SlotInterfaceConstructor
                                    initialConfig={formData.interface}
                                    onChange={(newConfig) => setFormData(prev => ({
                                        ...prev,
                                        interface: newConfig
                                    }))}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h5" gutterBottom>
                            Символы
                        </Typography>
                        <SymbolConstructor
                            symbols={symbols}
                            onChange={setSymbols}
                        />
                    </Paper>

                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h5" gutterBottom>
                            Линии выплат
                        </Typography>
                        <PaylineConstructor
                            paylines={paylines}
                            onChange={setPaylines}
                        />
                    </Paper>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.enabled}
                                    onChange={(e) => setFormData(prev => ({ ...prev, enabled: e.target.checked }))}
                                    name="enabled"
                                />
                            }
                            label="Активировать слот"
                        />
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? 'Создание...' : 'Создать слот'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default CreateSlot;
