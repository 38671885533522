import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Chip,
  Tooltip,
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import axios from 'axios';
import { formatCurrency } from '../../utils/formatters';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [statsDialogOpen, setStatsDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editUser, setEditUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, {
        params: {
          page: page + 1,
          limit: rowsPerPage
        }
      });
      setUsers(response.data.users);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (user) => {
    setEditUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditUser(null);
    setOpenDialog(false);
  };

  const handleSaveUser = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/users/${editUser._id}`, editUser);
      fetchUsers();
      handleCloseDialog();
    } catch (error) {
      console.error('Ошибка при обновлении пользователя:', error);
    }
  };

  const handleStatusChange = async (userId, newStatus) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/admin/users/${userId}/status`, {
        status: newStatus
      });
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при обновлении статуса пользователя:', error);
    }
  };

  const handleShowStats = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users/${userId}/stats`);
      setUserStats(response.data);
      setStatsDialogOpen(true);
      setSelectedUser(users.find(user => user._id === userId));
    } catch (error) {
      console.error('Ошибка при загрузке статистики пользователя:', error);
    }
  };

  const handleCloseStats = () => {
    setStatsDialogOpen(false);
    setUserStats(null);
    setSelectedUser(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Управление пользователями
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Имя пользователя</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Баланс</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>VIP уровень</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user._id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{formatCurrency(user.balance)}</TableCell>
                <TableCell>
                  <Chip
                    label={user.status === 'active' ? 'Активен' : 'Заблокирован'}
                    color={user.status === 'active' ? 'success' : 'error'}
                  />
                </TableCell>
                <TableCell>{user.vipLevel}</TableCell>
                <TableCell>
                  <Tooltip title="Редактировать">
                    <IconButton onClick={() => handleEditClick(user)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={user.status === 'active' ? 'Заблокировать' : 'Разблокировать'}>
                    <IconButton
                      onClick={() => handleStatusChange(user._id, user.status === 'active' ? 'banned' : 'active')}
                      color={user.status === 'active' ? 'error' : 'success'}
                    >
                      {user.status === 'active' ? <BlockIcon /> : <CheckCircleIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Просмотр статистики">
                    <IconButton 
                      color="primary"
                      onClick={() => handleShowStats(user._id)}
                    >
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </TableContainer>

      {/* Диалог статистики пользователя */}
      <Dialog
        open={statsDialogOpen}
        onClose={handleCloseStats}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Статистика пользователя: {selectedUser?.username}
        </DialogTitle>
        <DialogContent dividers>
          {userStats ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={2} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Игровая активность</Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary="Всего игр"
                        secondary={userStats.totalGames || 0}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Процент побед"
                        secondary={`${((userStats.winCount / userStats.totalGames) * 100 || 0).toFixed(1)}%`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Текущая серия"
                        secondary={userStats.currentStreak || 0}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Крупнейший выигрыш"
                        secondary={formatCurrency(userStats.biggestWin || 0)}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={2} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Финансовая сводка</Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary="Общая сумма ставок"
                        secondary={formatCurrency(userStats.totalWagered || 0)}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Общий выигрыш"
                        secondary={formatCurrency(userStats.totalWon || 0)}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Чистая прибыль/убыток"
                        secondary={formatCurrency((userStats.totalWon - userStats.totalWagered) || 0)}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Текущий баланс"
                        secondary={formatCurrency(selectedUser?.balance || 0)}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Детали аккаунта</Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText 
                        primary="Дата регистрации"
                        secondary={new Date(selectedUser?.createdAt).toLocaleDateString()}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Последний вход"
                        secondary={selectedUser?.lastLogin ? new Date(selectedUser.lastLogin).toLocaleString() : 'Никогда'}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="Статус"
                        secondary={selectedUser?.status === 'active' ? 'Активен' : 'Заблокирован'}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText 
                        primary="VIP уровень"
                        secondary={selectedUser?.vipLevel || 'Стандартный'}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStats}>Закрыть</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Редактировать пользователя</DialogTitle>
        <DialogContent>
          {editUser && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <TextField
                fullWidth
                label="Имя пользователя"
                value={editUser.username}
                onChange={(e) => setEditUser({ ...editUser, username: e.target.value })}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                value={editUser.email}
                onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                margin="normal"
              />
              <TextField
                fullWidth
                select
                label="Статус"
                value={editUser.status}
                onChange={(e) => setEditUser({ ...editUser, status: e.target.value })}
                margin="normal"
              >
                <MenuItem value="active">Активен</MenuItem>
                <MenuItem value="banned">Заблокирован</MenuItem>
              </TextField>
              <TextField
                fullWidth
                label="VIP уровень"
                type="number"
                value={editUser.vipLevel}
                onChange={(e) => setEditUser({ ...editUser, vipLevel: parseInt(e.target.value) })}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Баланс"
                type="number"
                value={editUser.balance}
                onChange={(e) => setEditUser({ ...editUser, balance: parseFloat(e.target.value) })}
                InputProps={{ inputProps: { min: 0 } }}
                margin="normal"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Отмена</Button>
          <Button onClick={handleSaveUser} variant="contained" color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Users;
