import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Alert
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { slotsAPI } from '../utils/api';
import { getErrorMessage } from '../utils/errorHandler';
import LoadingSpinner from '../components/LoadingSpinner';
import SlotMachine from '../components/SlotMachine';
import BettingPanel from '../components/BettingPanel';
import GameHistory from '../components/GameHistory';
import PlayerStats from '../components/PlayerStats';

const Slots = () => {
  const { user } = useAuth();
  const [slot, setSlot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [spinning, setSpinning] = useState(false);
  const [betAmount, setBetAmount] = useState('1');
  const [gameResult, setGameResult] = useState(null);
  const [gameHistory, setGameHistory] = useState([]);
  const [playerStats, setPlayerStats] = useState(null);
  const [error, setError] = useState('');

  const calculateStats = useCallback((games) => {
    const stats = {
      totalGames: games.length,
      winCount: games.filter(game => game.win).length,
      totalWagered: games.reduce((sum, game) => sum + game.betAmount, 0),
      totalWon: games.reduce((sum, game) => sum + (game.win ? game.winAmount : 0), 0),
      biggestWin: Math.max(...games.map(game => game.win ? game.winAmount : 0)),
      currentStreak: calculateStreak(games)
    };
    return stats;
  }, []);

  const calculateStreak = (games) => {
    let streak = 0;
    for (let i = 0; i < games.length; i++) {
      if (i === 0) {
        streak = games[i].win ? 1 : -1;
      } else if (games[i].win === games[i-1].win) {
        streak = games[i].win ? streak + 1 : streak - 1;
      } else {
        break;
      }
    }
    return streak;
  };

  const fetchInitialData = useCallback(async () => {
    try {
      const [slotResponse, historyResponse] = await Promise.all([
        slotsAPI.getSlots(),
        slotsAPI.getHistory()
      ]);

      setSlot(slotResponse.data[0]);
      setBetAmount(slotResponse.data[0].minBet.toString());
      setGameHistory(historyResponse.data.games);
      
      // Вычисляем статистику на основе истории игр
      const stats = calculateStats(historyResponse.data.games);
      setPlayerStats(stats);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [calculateStats]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const handleSpin = async () => {
    if (!slot || spinning) return;

    setError('');
    setSpinning(true);
    setGameResult(null);

    try {
      const response = await slotsAPI.playSlot(slot._id, Number(betAmount));
      
      setTimeout(() => {
        setGameResult(response.data);
        setGameHistory(prev => [response.data, ...prev].slice(0, 10));
        const stats = calculateStats([response.data, ...gameHistory]);
        setPlayerStats(stats);
        setSpinning(false);
      }, 2000);
    } catch (err) {
      setError(getErrorMessage(err));
      setSpinning(false);
    }
  };

  if (loading) {
    return <LoadingSpinner message="Loading slot machine..." />;
  }

  if (!slot) {
    return (
      <Container maxWidth="sm">
        <Alert severity="error">Failed to load slot machine</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          {slot.name}
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <SlotMachine
              spinning={spinning}
              result={gameResult}
            />
            <Box sx={{ mt: 3 }}>
              <PlayerStats stats={playerStats} />
            </Box>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BettingPanel
                  balance={user?.balance || 0}
                  betAmount={betAmount}
                  onBetChange={setBetAmount}
                  onSpin={handleSpin}
                  minBet={slot.minBet}
                  maxBet={slot.maxBet}
                  disabled={spinning}
                />
              </Grid>
              <Grid item xs={12}>
                <GameHistory history={gameHistory} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Slots;
