import React from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Button,
  Box 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Welcome to Casino
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {user ? `Welcome back, ${user.username}!` : 'Experience the thrill of gaming'}
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/slots-preview.jpg"
                alt="Slot Games"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Slot Games
                </Typography>
                <Typography>
                  Try your luck with our exciting collection of slot games!
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  sx={{ mt: 2 }}
                  onClick={() => navigate('/slots')}
                >
                  Play Now
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {!user && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Join Now
                    </Typography>
                    <Typography>
                      Create an account to start playing and track your progress!
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      fullWidth 
                      sx={{ mt: 2 }}
                      onClick={() => navigate('/register')}
                    >
                      Register
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Already a Member?
                    </Typography>
                    <Typography>
                      Login to access your account and continue playing!
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      fullWidth 
                      sx={{ mt: 2 }}
                      onClick={() => navigate('/login')}
                    >
                      Login
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default Home;
