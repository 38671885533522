import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Avatar,
  Divider,
  Alert
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const Profile = () => {
  const { user, logout } = useAuth();
  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [formData, setFormData] = useState({
    username: user?.username || '',
    email: user?.email || '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', text: '' });

    try {
      if (formData.newPassword && formData.newPassword !== formData.confirmNewPassword) {
        setMessage({ type: 'error', text: 'New passwords do not match' });
        return;
      }

      const updateData = {
        username: formData.username,
        email: formData.email
      };

      if (formData.currentPassword && formData.newPassword) {
        updateData.currentPassword = formData.currentPassword;
        updateData.newPassword = formData.newPassword;
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/users/me`, updateData);
      setMessage({ type: 'success', text: 'Profile updated successfully' });
      setEditing(false);
    } catch (err) {
      setMessage({
        type: 'error',
        text: err.response?.data?.message || 'Failed to update profile'
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12} display="flex" justifyContent="center">
              <Avatar
                sx={{
                  width: 100,
                  height: 100,
                  bgcolor: 'primary.main',
                  fontSize: '2rem'
                }}
              >
                {user?.username?.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                Profile
              </Typography>
            </Grid>

            {message.text && (
              <Grid item xs={12}>
                <Alert severity={message.type}>{message.text}</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled={!editing}
                    />
                  </Grid>

                  {editing && (
                    <>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }}>
                          <Typography color="textSecondary">
                            Change Password (Optional)
                          </Typography>
                        </Divider>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Current Password"
                          name="currentPassword"
                          type="password"
                          value={formData.currentPassword}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="New Password"
                          name="newPassword"
                          type="password"
                          value={formData.newPassword}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Confirm New Password"
                          name="confirmNewPassword"
                          type="password"
                          value={formData.confirmNewPassword}
                          onChange={handleChange}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} display="flex" gap={2} justifyContent="center">
                    {!editing ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditing(true)}
                      >
                        Edit Profile
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Save Changes
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setEditing(false)}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                color="error"
                onClick={logout}
                sx={{ mt: 2 }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default Profile;
