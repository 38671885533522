import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { formatCurrency } from '../utils/formatters';

const StatItem = ({ label, value, tooltip, trend }) => (
  <Box sx={{ textAlign: 'center', p: 1 }}>
    <Typography variant="body2" color="text.secondary" gutterBottom>
      {label}
      {tooltip && (
        <Tooltip title={tooltip} placement="top">
          <IconButton size="small" sx={{ ml: 0.5 }}>
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </Typography>
    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {value}
      {trend !== undefined && (
        <Box component="span" sx={{ ml: 1, color: trend >= 0 ? 'success.main' : 'error.main' }}>
          {trend >= 0 ? <TrendingUpIcon /> : <TrendingDownIcon />}
        </Box>
      )}
    </Typography>
  </Box>
);

const PlayerStats = ({ stats }) => {
  const {
    totalGames = 0,
    winCount = 0,
    totalWagered = 0,
    totalWon = 0,
    biggestWin = 0,
    currentStreak = 0
  } = stats || {};

  const winRate = totalGames > 0 ? ((winCount / totalGames) * 100).toFixed(1) : 0;
  const profitLoss = totalWon - totalWagered;

  return (
    <Paper elevation={3} sx={{ p: 2, backgroundColor: 'background.paper' }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Player Statistics
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <StatItem
            label="Total Games"
            value={totalGames}
            tooltip="Total number of games played"
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <StatItem
            label="Win Rate"
            value={`${winRate}%`}
            tooltip="Percentage of games won"
            trend={winRate >= 50 ? 1 : -1}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <StatItem
            label="Current Streak"
            value={Math.abs(currentStreak)}
            tooltip={`${currentStreak >= 0 ? 'Win' : 'Loss'} streak`}
            trend={currentStreak}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <StatItem
            label="Total Wagered"
            value={formatCurrency(totalWagered)}
            tooltip="Total amount bet"
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <StatItem
            label="Total Won"
            value={formatCurrency(totalWon)}
            tooltip="Total amount won"
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <StatItem
            label="Profit/Loss"
            value={formatCurrency(Math.abs(profitLoss))}
            tooltip="Total profit or loss"
            trend={profitLoss >= 0 ? 1 : -1}
          />
        </Grid>

        <Grid item xs={12}>
          <StatItem
            label="Biggest Win"
            value={formatCurrency(biggestWin)}
            tooltip="Largest amount won in a single game"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PlayerStats;
