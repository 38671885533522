import React, { useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    Collapse,
    IconButton,
    Typography,
    Grid,
    Paper,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Tabs,
    Tab,
    Checkbox,
    Slider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TextureManager from './TextureManager';

const SlotInterfaceConstructor = ({ onChange, initialConfig = {} }) => {
    const canvasRef = useRef(null);
    const appRef = useRef(null);
    const gameAreaRef = useRef(null);
    const reelAreaRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [texturesLoaded, setTexturesLoaded] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [config, setConfig] = useState({
        gameArea: {
            x: 400,
            y: 200,
            width: 800,
            height: 600,
            background: '',
        },
        reelArea: {
            x: 250,
            y: 100,
            width: 500,
            height: 400,
            background: '',
        },
        textures: [],
        animations: [],
        ...initialConfig
    });

    const [dragState, setDragState] = useState({ isDragging: false });
    const [showTextureManager, setShowTextureManager] = useState(false);
    const [textureTarget, setTextureTarget] = useState(null);

    const [textures, setTextures] = useState([]);
    const [isTextureManagerOpen, setIsTextureManagerOpen] = useState(false);
    const [selectedTextureType, setSelectedTextureType] = useState(null);
    const [selectedTextureIndex, setSelectedTextureIndex] = useState(null);

    const handleUploadTexture = (type) => {
        setSelectedTextureType(type);
        setIsTextureManagerOpen(true);
    };

    const getFullTextureUrl = (path) => {
        return `${process.env.REACT_APP_STATIC_URL}${path}`;
    };

    const handleTextureSelect = (texture) => {
        if (!texture) return;

        const newConfig = { ...config };
        
        if (selectedTextureType === 'gameArea') {
            newConfig.gameArea.background = texture.url;
        } else if (selectedTextureType === 'reelArea') {
            newConfig.reelArea.background = texture.url;
        } else if (selectedTextureType === 'texture') {
            const newTexture = {
                id: Date.now(),
                image: texture.url,
                x: 0,
                y: 0,
                width: 100,
                height: 100,
                isAnimated: texture.isAnimated || false,
                frames: texture.frames || [],
                animationSpeed: texture.animationSpeed || 0.1
            };
            newConfig.textures.push(newTexture);
        }

        setConfig(newConfig);
        setIsTextureManagerOpen(false);
    };

    const handleTextureSelectIndex = (index) => {
        setSelectedTextureIndex(selectedTextureIndex === index ? null : index);
    };

    // Инициализация PIXI приложения
    useEffect(() => {
        const initPixiApp = async () => {
            if (appRef.current) return;

            const currentCanvas = canvasRef.current;
            if (!currentCanvas) return;

            try {
                // Создаем canvas элемент
                const canvas = document.createElement('canvas');
                canvas.style.width = '100%';
                canvas.style.height = '100%';
                canvas.style.display = 'block';

                // Очищаем контейнер
                while (currentCanvas.firstChild) {
                    currentCanvas.removeChild(currentCanvas.firstChild);
                }

                // Добавляем canvas в DOM
                currentCanvas.appendChild(canvas);

                // Создаем приложение PIXI используя новый метод init
                const app = new PIXI.Application();
                await app.init({
                    canvas, // используем canvas вместо view
                    width: 1600,
                    height: 900,
                    backgroundColor: 0x1a1a1a,
                    resolution: window.devicePixelRatio || 1,
                    antialias: true,
                    autoDensity: true,
                    eventFeatures: { // Включаем все события мыши
                        move: true,
                        globalMove: true,
                        click: true,
                        wheel: true
                    }
                });

                // Создаем основные контейнеры
                const gameArea = new PIXI.Container();
                const reelArea = new PIXI.Container();
                
                app.stage.addChild(gameArea);
                gameArea.addChild(reelArea);

                gameAreaRef.current = gameArea;
                reelAreaRef.current = reelArea;
                appRef.current = app;
            } catch (error) {
                console.error('Error initializing PIXI application:', error);
            }
        };

        initPixiApp();

        return () => {
            const app = appRef.current;
            if (!app) return;

            try {
                // Останавливаем все анимации
                if (gameAreaRef.current) {
                    gameAreaRef.current.children.forEach(child => {
                        if (child.animationTicker) {
                            app.ticker.remove(child.animationTicker);
                        }
                    });
                }

                // Очищаем сцену
                if (app.stage) {
                    while (app.stage.children.length > 0) {
                        const child = app.stage.children[0];
                        app.stage.removeChild(child);
                        child.destroy({ children: true });
                    }
                }

                // Останавливаем ticker и уничтожаем приложение
                app.ticker.destroy();
                app.stop();
                app.destroy(true, { children: true });
                
                // Очищаем ссылки
                appRef.current = null;
                gameAreaRef.current = null;
                reelAreaRef.current = null;

            } catch (error) {
                console.error('Error cleaning up PIXI application:', error);
            }
        };
    }, []);

    // Загрузка и обновление текстур
    useEffect(() => {
        const loadTextures = async () => {
            if (!appRef.current || !gameAreaRef.current || !reelAreaRef.current) return;

            try {
                // Очищаем существующие текстуры
                while (gameAreaRef.current.children[0]) {
                    gameAreaRef.current.removeChild(gameAreaRef.current.children[0]);
                }
                while (reelAreaRef.current.children[0]) {
                    reelAreaRef.current.removeChild(reelAreaRef.current.children[0]);
                }

                // Загружаем фон игровой области
                if (config.gameArea.background) {
                    try {
                        const texture = await PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + config.gameArea.background);
                        const gameAreaBg = new PIXI.Sprite(texture);
                        if (gameAreaBg) {
                            const containerWidth = appRef.current.renderer.width;
                            const containerHeight = appRef.current.renderer.height;
                            
                            gameAreaBg.width = parseSize(config.gameArea.width, containerWidth);
                            gameAreaBg.height = parseSize(config.gameArea.height, containerHeight);
                            gameAreaBg.x = config.gameArea.x;
                            gameAreaBg.y = config.gameArea.y;
                            gameAreaBg.isBackground = true;
                            gameAreaRef.current.addChild(gameAreaBg);
                        }
                    } catch (error) {
                        console.error('Error loading game area background:', error);
                    }
                }

                // Загружаем фон области барабанов
                if (config.reelArea.background) {
                    try {
                        const texture = await PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + config.reelArea.background);
                        const reelAreaBg = new PIXI.Sprite(texture);
                        if (reelAreaBg) {
                            const containerWidth = appRef.current.renderer.width;
                            const containerHeight = appRef.current.renderer.height;
                            
                            reelAreaBg.width = parseSize(config.reelArea.width, containerWidth);
                            reelAreaBg.height = parseSize(config.reelArea.height, containerHeight);
                            reelAreaBg.x = config.reelArea.x;
                            reelAreaBg.y = config.reelArea.y;
                            reelAreaBg.isBackground = true;
                            reelAreaRef.current.addChild(reelAreaBg);
                        }
                    } catch (error) {
                        console.error('Error loading reel area background:', error);
                    }
                }
                
                // Загружаем и отображаем текстуры
                for (let index = 0; index < config.textures.length; index++) {
                    const texture = config.textures[index];
                    if (texture.image) {
                        try {
                            let sprite;

                            if (texture.isAnimated && texture.frames && texture.frames.length > 0) {
                                // Загружаем все кадры анимации
                                const frameTextures = await Promise.all(
                                    texture.frames.map(frame => 
                                        PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + frame.url)
                                    )
                                );
                                
                                sprite = new PIXI.AnimatedSprite(frameTextures);
                                sprite.animationSpeed = texture.animationSpeed || 0.1;
                                sprite.play();
                            } else {
                                const loadedTexture = await PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + texture.image);
                                sprite = new PIXI.Sprite(loadedTexture);
                            }

                            if (sprite) {
                                const containerWidth = appRef.current.renderer.width;
                                const containerHeight = appRef.current.renderer.height;
                                
                                sprite.width = parseSize(texture.width || '10%', containerWidth);
                                sprite.height = parseSize(texture.height || '10%', containerHeight);
                                sprite.x = texture.x || 0;
                                sprite.y = texture.y || 0;
                                sprite.textureId = `texture_${index}`;
                                
                                sprite.eventMode = 'dynamic';
                                sprite.cursor = 'move';
                                sprite.interactive = true;

                                sprite
                                    .on('pointerdown', onDragStart)
                                    .on('pointerup', onDragEnd)
                                    .on('pointerupoutside', onDragEnd)
                                    .on('pointermove', onDragMove);

                                gameAreaRef.current.addChild(sprite);
                            }
                        } catch (error) {
                            console.error('Error loading texture:', error);
                        }
                    }
                }
            } catch (error) {
                console.error('Error in loadTextures:', error);
            }
        };

        loadTextures();
    }, [config]);

    // Обновление отображения при изменении конфига
    useEffect(() => {
        // Если изменение вызвано перетаскиванием или текстуры еще не загружены, пропускаем обновление
        if (dragState.isDragging || !texturesLoaded) return;

        const app = appRef.current;
        const gameArea = gameAreaRef.current;
        const reelArea = reelAreaRef.current;
        
        if (!app?.stage || !gameArea || !reelArea) return;

        try {
            // Очищаем текущее отображение
            const loadTextures = async () => {
                if (!app?.stage || !gameArea || !reelArea) return;

                try {
                    // Очищаем текущее отображение
                    while (gameArea.children[0]) {
                        gameArea.removeChild(gameArea.children[0]);
                    }
                    while (reelArea.children[0]) {
                        reelArea.removeChild(reelArea.children[0]);
                    }

                    // Загружаем фон игровой области
                    if (config.gameArea.background) {
                        try {
                            const texture = await PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + config.gameArea.background);
                            const gameAreaBg = new PIXI.Sprite(texture);
                            if (gameAreaBg) {
                                const containerWidth = appRef.current.renderer.width;
                                const containerHeight = appRef.current.renderer.height;
                                
                                gameAreaBg.width = parseSize(config.gameArea.width, containerWidth);
                                gameAreaBg.height = parseSize(config.gameArea.height, containerHeight);
                                gameAreaBg.x = config.gameArea.x;
                                gameAreaBg.y = config.gameArea.y;
                                gameAreaBg.isBackground = true;
                                gameArea.addChildAt(gameAreaBg, 0);

                                // Фиксируем позицию в 0,0
                                gameArea.x = 0;
                                gameArea.y = 0;
                                
                                // Отключаем интерактивность
                                gameArea.eventMode = 'none';
                                gameArea.cursor = 'default';
                                gameArea.interactive = false;
                            }
                        } catch (error) {
                            console.error('Error loading game area background:', error);
                        }
                    }

                    // Загружаем фон области барабанов
                    if (config.reelArea.background) {
                        try {
                            const texture = await PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + config.reelArea.background);
                            const reelAreaBg = new PIXI.Sprite(texture);
                            if (reelAreaBg) {
                                const containerWidth = appRef.current.renderer.width;
                                const containerHeight = appRef.current.renderer.height;
                                
                                reelAreaBg.width = parseSize(config.reelArea.width, containerWidth);
                                reelAreaBg.height = parseSize(config.reelArea.height, containerHeight);
                                reelAreaBg.x = config.reelArea.x;
                                reelAreaBg.y = config.reelArea.y;
                                reelAreaBg.isBackground = true;
                                reelArea.addChild(reelAreaBg);

                                // Фиксируем позицию в 0,0
                                reelArea.x = 0;
                                reelArea.y = 0;
                                
                                // Отключаем интерактивность
                                reelArea.eventMode = 'none';
                                reelArea.cursor = 'default';
                                reelArea.interactive = false;
                            }
                        } catch (error) {
                            console.error('Error loading reel area background:', error);
                        }
                    }
                    
                    // Загружаем и отображаем текстуры
                    for (let index = 0; index < config.textures.length; index++) {
                        const texture = config.textures[index];
                        if (texture.image) {
                            try {
                                let sprite;

                                if (texture.isAnimated && texture.frames && texture.frames.length > 0) {
                                    // Загружаем все кадры анимации
                                    const frameTextures = await Promise.all(
                                        texture.frames.map(frame => 
                                            PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + frame.url)
                                        )
                                    );
                                    
                                    sprite = new PIXI.AnimatedSprite(frameTextures);
                                    sprite.animationSpeed = texture.animationSpeed || 0.1;
                                    sprite.play();
                                } else {
                                    const loadedTexture = await PIXI.Assets.load(process.env.REACT_APP_STATIC_URL + texture.image);
                                    sprite = new PIXI.Sprite(loadedTexture);
                                }

                                if (sprite) {
                                    const containerWidth = appRef.current.renderer.width;
                                    const containerHeight = appRef.current.renderer.height;
                                    
                                    sprite.width = parseSize(texture.width || '10%', containerWidth);
                                    sprite.height = parseSize(texture.height || '10%', containerHeight);
                                    sprite.x = texture.x || 0;
                                    sprite.y = texture.y || 0;
                                    sprite.textureId = `texture_${index}`;
                                    
                                    sprite.eventMode = 'dynamic';
                                    sprite.cursor = 'move';
                                    sprite.interactive = true;

                                    sprite
                                        .on('pointerdown', onDragStart)
                                        .on('pointerup', onDragEnd)
                                        .on('pointerupoutside', onDragEnd)
                                        .on('pointermove', onDragMove);

                                    gameArea.addChild(sprite);
                                }
                            } catch (error) {
                                console.error('Error loading texture:', error);
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error in loadTextures:', error);
                }
            };

            loadTextures();

            // Отрисовка игровой области
            if (config.gameArea.background) {
                const gameAreaBg = new PIXI.Sprite(PIXI.Assets.get(config.gameArea.background));
                if (gameAreaBg) {
                    const containerWidth = appRef.current.renderer.width;
                    const containerHeight = appRef.current.renderer.height;
                    
                    gameAreaBg.width = parseSize(config.gameArea.width || '80%', containerWidth);
                    gameAreaBg.height = parseSize(config.gameArea.height || '80%', containerHeight);
                    gameArea.addChildAt(gameAreaBg, 0);

                    // Фиксируем позицию в 0,0
                    gameArea.x = 0;
                    gameArea.y = 0;
                    
                    // Отключаем интерактивность
                    gameArea.eventMode = 'none';
                    gameArea.cursor = 'default';
                    gameArea.interactive = false;
                }
            }

            // Отрисовка области барабанов
            if (config.reelArea.background) {
                const reelAreaBg = new PIXI.Sprite(PIXI.Assets.get(config.reelArea.background));
                if (reelAreaBg) {
                    const containerWidth = appRef.current.renderer.width;
                    const containerHeight = appRef.current.renderer.height;
                    
                    reelAreaBg.width = parseSize(config.reelArea.width || '50%', containerWidth);
                    reelAreaBg.height = parseSize(config.reelArea.height || '50%', containerHeight);
                    reelArea.addChild(reelAreaBg);

                    // Фиксируем позицию в 0,0
                    reelArea.x = 0;
                    reelArea.y = 0;
                    
                    // Отключаем интерактивность
                    reelArea.eventMode = 'none';
                    reelArea.cursor = 'default';
                    reelArea.interactive = false;
                }
            }

            // Позиционирование областей
            if (gameArea && gameArea.position) {
                gameArea.position.set(config.gameArea.x || 0, config.gameArea.y || 0);
            }
            if (reelArea && reelArea.position) {
                reelArea.position.set(config.reelArea.x || 0, config.reelArea.y || 0);
            }

            // Применяем анимации
            config.animations.forEach((animation, index) => {
                if (animation.targetIndex >= 0 && animation.targetIndex < gameArea.children.length) {
                    const target = gameArea.children[animation.targetIndex];
                    let elapsed = 0;
                    
                    const animationTicker = (delta) => {
                        elapsed += delta;
                        
                        switch (animation.type) {
                            case 'rotation':
                                target.rotation += (animation.speed || 0.1) * delta;
                                break;
                            case 'scale':
                                const scale = 1 + Math.sin(elapsed * (animation.speed || 0.05)) * (animation.magnitude || 0.2);
                                target.scale.set(scale);
                                break;
                            default:
                                console.warn(`Неизвестный тип анимации: ${animation.type}`);
                                break;
                        }
                    };
                    
                    app.ticker.add(animationTicker);
                    
                    // Сохраняем функцию тикера для возможности её удаления при обновлении конфига
                    target.animationTicker = animationTicker;
                }
            });

            // Оповещаем о изменениях
            onChange?.(config);
        } catch (error) {
            console.error('Error updating display:', error);
        }
    }, [config, onChange, texturesLoaded]);

    // Обработчики для drag & drop
    const onDragStart = (event) => {
        const sprite = event.currentTarget;
        sprite.alpha = 0.5;
        sprite.dragging = true;
        sprite.dragData = event.data;
        sprite.dragStartPosition = { x: sprite.x, y: sprite.y };
    };

    const onDragEnd = (event) => {
        const sprite = event.currentTarget;
        sprite.alpha = 1;
        sprite.dragging = false;
        sprite.dragData = null;

        // Обновляем конфиг с новой позицией
        const textureIndex = config.textures.findIndex(t => t.id === sprite.textureId);
        if (textureIndex !== -1) {
            const newConfig = { ...config };
            newConfig.textures[textureIndex] = {
                ...newConfig.textures[textureIndex],
                x: Math.round(sprite.x),
                y: Math.round(sprite.y)
            };
            setConfig(newConfig);
        }
    };

    const onDragMove = (event) => {
        const sprite = event.currentTarget;
        if (sprite.dragging) {
            const newPosition = sprite.dragData.getLocalPosition(sprite.parent);
            sprite.x = newPosition.x;
            sprite.y = newPosition.y;
        }
    };

    // Функция для парсинга размеров с единицами измерения
    const parseSize = (size, containerSize, dimension = 'width') => {
        if (typeof size === 'number') return size;
        if (typeof size !== 'string') return 0;

        const match = size.match(/^(\d+(?:\.\d+)?)(px|%|vw|vh)$/);
        if (!match) return 0;

        const [, value, unit] = match;
        const numValue = parseFloat(value);

        switch (unit) {
            case '%':
                return (containerSize * numValue) / 100;
            case 'vw':
                return (window.innerWidth * numValue) / 100;
            case 'vh':
                return (window.innerHeight * numValue) / 100;
            default:
                return numValue;
        }
    };

    // Обработчик изменения скорости анимации
    const handleAnimationSpeedChange = (newSpeed) => {
        if (selectedTextureIndex === null) return;

        const newConfig = { ...config };
        newConfig.textures[selectedTextureIndex] = {
            ...newConfig.textures[selectedTextureIndex],
            animationSpeed: newSpeed
        };
        setConfig(newConfig);
        
        // Обновляем скорость анимации в спрайте
        if (gameAreaRef.current) {
            const sprites = gameAreaRef.current.children;
            const targetSprite = sprites.find(
                sprite => sprite.textureId === `texture_${selectedTextureIndex}`
            );
            if (targetSprite && targetSprite instanceof PIXI.AnimatedSprite) {
                targetSprite.animationSpeed = newSpeed;
            }
        }

        if (onChange) {
            onChange(newConfig);
        }
    };

    // Обработчик изменения размера окна
    useEffect(() => {
        const handleResize = () => {
            if (appRef.current?.renderer) {
                const newWidth = window.innerWidth;
                const newHeight = window.innerHeight;
                
                appRef.current.renderer.resize(newWidth, newHeight);
                
                // Обновляем размеры областей при изменении окна
                if (config.gameArea) {
                    const gameAreaWidth = parseSize(config.gameArea.width, newWidth);
                    const gameAreaHeight = parseSize(config.gameArea.height, newHeight);
                    if (gameAreaRef.current) {
                        const gameArea = gameAreaRef.current;
                        if (gameArea.children[0]) {
                            gameArea.children[0].width = gameAreaWidth;
                            gameArea.children[0].height = gameAreaHeight;
                        }
                    }
                }
                
                if (config.reelArea) {
                    const reelAreaWidth = parseSize(config.reelArea.width, newWidth);
                    const reelAreaHeight = parseSize(config.reelArea.height, newHeight);
                    if (reelAreaRef.current) {
                        const reelArea = reelAreaRef.current;
                        if (reelArea.children[0]) {
                            reelArea.children[0].width = reelAreaWidth;
                            reelArea.children[0].height = reelAreaHeight;
                        }
                    }
                }
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [appRef, config]);

    const handleConfigChange = (section, field, value) => {
        setConfig(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value
            }
        }));
    };

    const handleAddTexture = () => {
        setConfig(prev => ({
            ...prev,
            textures: [...prev.textures, {
                image: '',
                x: 0,
                y: 0,
                width: 100,
                height: 100
            }]
        }));
    };

    const handleUpdateTexture = (index, field, value) => {
        setConfig(prev => ({
            ...prev,
            textures: prev.textures.map((texture, i) =>
                i === index ? { ...texture, [field]: value } : texture
            )
        }));
    };

    const handleRemoveTexture = (index) => {
        setConfig(prev => ({
            ...prev,
            textures: prev.textures.filter((_, i) => i !== index)
        }));
    };

    const handleAddAnimation = () => {
        setConfig(prev => ({
            ...prev,
            animations: [...prev.animations, {
                targetIndex: 0,
                type: 'rotation',
                speed: 0.1,
                magnitude: 0.2
            }]
        }));
    };

    const handleUpdateAnimation = (index, field, value) => {
        setConfig(prev => ({
            ...prev,
            animations: prev.animations.map((animation, i) =>
                i === index ? { ...animation, [field]: value } : animation
            )
        }));
    };

    const handleRemoveAnimation = (index) => {
        setConfig(prev => ({
            ...prev,
            animations: prev.animations.filter((_, i) => i !== index)
        }));
    };

    const handleOpenTextureManager = (target) => {
        setTextureTarget(target);
        setShowTextureManager(true);
    };

    return (
        <Box>
            <Card>
                <CardHeader
                    title={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="h6">Конструктор интерфейса</Typography>
                            <IconButton onClick={() => setIsExpanded(!isExpanded)} size="small">
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>
                    }
                />
                <Collapse in={isExpanded}>
                    <CardContent>
                        <Box sx={{ mb: 2 }}>
                            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                                <Tab label="Игровая область" />
                                <Tab label="Область барабанов" />
                                <Tab label="Текстуры" />
                                <Tab label="Анимации" />
                            </Tabs>
                        </Box>

                        {/* Canvas для предпросмотра */}
                        <Box 
                            ref={canvasRef} 
                            sx={{
                                width: '100%',
                                height: '500px',
                                bgcolor: '#1a1a1a',
                                mb: 2,
                                borderRadius: 1,
                                overflow: 'hidden'
                            }}
                        />

                        {/* Панель настроек */}
                        <Grid container spacing={2}>
                            {/* Настройки игровой области */}
                            {activeTab === 0 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="X"
                                            type="number"
                                            value={config.gameArea.x}
                                            onChange={(e) => handleConfigChange('gameArea', 'x', Number(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="Y"
                                            type="number"
                                            value={config.gameArea.y}
                                            onChange={(e) => handleConfigChange('gameArea', 'y', Number(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="Ширина"
                                            type="text"
                                            value={config.gameArea.width}
                                            onChange={(e) => handleConfigChange('gameArea', 'width', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="Высота"
                                            type="text"
                                            value={config.gameArea.height}
                                            onChange={(e) => handleConfigChange('gameArea', 'height', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon />}
                                            onClick={() => handleUploadTexture('gameArea')}
                                        >
                                            Загрузить текстуру
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}

                            {/* Настройки области барабанов */}
                            {activeTab === 1 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="X"
                                            type="number"
                                            value={config.reelArea.x}
                                            onChange={(e) => handleConfigChange('reelArea', 'x', Number(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="Y"
                                            type="number"
                                            value={config.reelArea.y}
                                            onChange={(e) => handleConfigChange('reelArea', 'y', Number(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="Ширина"
                                            type="text"
                                            value={config.reelArea.width}
                                            onChange={(e) => handleConfigChange('reelArea', 'width', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            fullWidth
                                            label="Высота"
                                            type="text"
                                            value={config.reelArea.height}
                                            onChange={(e) => handleConfigChange('reelArea', 'height', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon />}
                                            onClick={() => handleUploadTexture('reelArea')}
                                        >
                                            Загрузить текстуру
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}

                            {/* Управление текстурами */}
                            {activeTab === 2 && (
                                <>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleUploadTexture('texture')}
                                        sx={{ mb: 2 }}
                                    >
                                        Добавить текстуру
                                    </Button>
                                    <Grid container spacing={2}>
                                        {config.textures.map((texture, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Paper sx={{ p: 2 }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} sm={1}>
                                                            <Checkbox
                                                                checked={selectedTextureIndex === index}
                                                                onChange={() => handleTextureSelectIndex(index)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <TextField
                                                                fullWidth
                                                                label="URL изображения"
                                                                value={texture.image}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={2}>
                                                            <TextField
                                                                fullWidth
                                                                label="X"
                                                                type="number"
                                                                value={texture.x}
                                                                onChange={(e) => handleUpdateTexture(index, 'x', Number(e.target.value))}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={2}>
                                                            <TextField
                                                                fullWidth
                                                                label="Y"
                                                                type="number"
                                                                value={texture.y}
                                                                onChange={(e) => handleUpdateTexture(index, 'y', Number(e.target.value))}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={2}>
                                                            <TextField
                                                                fullWidth
                                                                label="Ширина"
                                                                type="text"
                                                                value={texture.width}
                                                                onChange={(e) => handleUpdateTexture(index, 'width', e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={1}>
                                                            <TextField
                                                                fullWidth
                                                                label="Высота"
                                                                type="text"
                                                                value={texture.height}
                                                                onChange={(e) => handleUpdateTexture(index, 'height', e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={1}>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => handleRemoveTexture(index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            )}

                            {/* Управление анимациями */}
                            {activeTab === 3 && (
                                <>
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={handleAddAnimation}
                                        sx={{ mb: 2 }}
                                    >
                                        Добавить анимацию
                                    </Button>
                                    <Grid container spacing={2}>
                                        {config.animations.map((animation, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Paper sx={{ p: 2 }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} sm={3}>
                                                            <TextField
                                                                fullWidth
                                                                label="Индекс объекта"
                                                                type="number"
                                                                value={animation.targetIndex}
                                                                onChange={(e) => handleUpdateAnimation(index, 'targetIndex', Number(e.target.value))}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <FormControl fullWidth>
                                                                <InputLabel>Тип анимации</InputLabel>
                                                                <Select
                                                                    value={animation.type}
                                                                    label="Тип анимации"
                                                                    onChange={(e) => handleUpdateAnimation(index, 'type', e.target.value)}
                                                                >
                                                                    <MenuItem value="rotation">Вращение</MenuItem>
                                                                    <MenuItem value="scale">Масштабирование</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            <TextField
                                                                fullWidth
                                                                label="Скорость"
                                                                type="number"
                                                                inputProps={{ step: 0.1 }}
                                                                value={animation.speed}
                                                                onChange={(e) => handleUpdateAnimation(index, 'speed', Number(e.target.value))}
                                                            />
                                                        </Grid>
                                                        {animation.type === 'scale' && (
                                                            <Grid item xs={12} sm={2}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Амплитуда"
                                                                    type="number"
                                                                    inputProps={{ step: 0.1 }}
                                                                    value={animation.magnitude}
                                                                    onChange={(e) => handleUpdateAnimation(index, 'magnitude', Number(e.target.value))}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} sm={1}>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => handleRemoveAnimation(index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </>
                            )}
                            {selectedTextureIndex !== null && config.textures[selectedTextureIndex]?.frames?.length > 0 && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Animation Settings
                                    </Typography>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography>Speed:</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Slider
                                                value={config.textures[selectedTextureIndex].animationSpeed || 0.1}
                                                onChange={(_, value) => handleAnimationSpeedChange(value)}
                                                min={0.01}
                                                max={1}
                                                step={0.01}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => value.toFixed(2)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>

            {/* Менеджер текстур */}
            {isTextureManagerOpen && (
                <TextureManager
                    onSelect={handleTextureSelect}
                    onClose={() => setIsTextureManagerOpen(false)}
                />
            )}
        </Box>
    );
};

export default SlotInterfaceConstructor;
