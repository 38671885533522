import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    IconButton,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import api from '../../utils/api';

const TextureManager = ({ onSelect, onClose }) => {
    const [textures, setTextures] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);

    // Загрузка списка текстур
    const loadTextures = async () => {
        try {
            const response = await api.get('/admin/textures/list');
            setTextures(response.data);
        } catch (error) {
            console.error('Error loading textures:', error);
            setError('Failed to load textures');
        }
    };

    useEffect(() => {
        loadTextures();
    }, []);

    // Загрузка новой текстуры
    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('texture', selectedFile);

        setUploading(true);
        setError(null);

        try {
            const response = await api.post('/admin/textures/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setTextures([...textures, response.data]);
            setSelectedFile(null);
        } catch (error) {
            console.error('Error uploading texture:', error);
            setError('Failed to upload texture');
        } finally {
            setUploading(false);
        }
    };

    // Удаление текстуры
    const handleDelete = async (name) => {
        try {
            await api.delete(`/admin/textures/${name}`);
            setTextures(textures.filter(texture => texture.name !== name));
        } catch (error) {
            console.error('Error deleting texture:', error);
            setError('Failed to delete texture');
        }
    };

    // Выбор текстуры
    const handleSelect = (texture) => {
        if (onSelect) {
            onSelect(texture);
        }
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Texture Manager
                <Box sx={{ float: 'right' }}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="upload-texture"
                        type="file"
                        onChange={handleFileSelect}
                    />
                    <label htmlFor="upload-texture">
                        <Button
                            component="span"
                            startIcon={<AddIcon />}
                            variant="contained"
                            disabled={uploading}
                        >
                            Upload New
                        </Button>
                    </label>
                    {selectedFile && (
                        <Button
                            onClick={handleUpload}
                            disabled={uploading}
                            sx={{ ml: 1 }}
                        >
                            {uploading ? 'Uploading...' : 'Save'}
                        </Button>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                {error && (
                    <Typography color="error" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}
                <Grid container spacing={2}>
                    {textures.map((texture, index) => (
                        <Grid item xs={12} sm={6} md={4} key={texture._id || texture.name || index}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={`${process.env.REACT_APP_STATIC_URL}${texture.url}`} 
                                    alt={texture.name || texture.url}
                                    sx={{ objectFit: 'contain', cursor: 'pointer', backgroundColor: '#f0f0f0' }}
                                    onClick={() => handleSelect(texture)}
                                    onError={(e) => {
                                        console.error('Error loading image:', `${process.env.REACT_APP_STATIC_URL}${texture.url}`);
                                        console.error(e);
                                    }}
                                />
                                <CardContent>
                                    <Typography variant="body2" noWrap>
                                        {texture.originalName || texture.name}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {(texture.size / 1024).toFixed(1)} KB
                                    </Typography>
                                    <Typography variant="caption" display="block" color="text.secondary">
                                        URL: {texture.url}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDelete(texture.name)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TextureManager;
